import axios from "axios";
import {
  LOGIN_USER,
  REGISTER_USER,
  AUTH_USER,
  LOGOUT_USER,
  ADD_TO_CART_USER,
  GET_CART_ITEM_USER,
  REMOVE_CART_ITEM_USER,
  ON_SUCCESS_BUY_USER,
  ADD_TO_ORDER_USER,
  ON_ORDER_SUCCESS,
  REQUEST_DELIVERY, 
  ON_DELIVERY_ORDER_SUCCESS,
  ON_ACCEPT_DELIVERY_SUCCESS,
  ON_SEND_DELIVERY_FEEDBACK,
  APPLY_DISCOUNT,
  ADD_PRODUCER_PAYMENT_INFO
} from "./types";
import { USER_SERVER } from "../components/Config.js";

export function registerUser(dataToSubmit) {
  const request = axios
    .post(`${USER_SERVER}/register`, dataToSubmit)
    .then((response) => {
      
      
      return response.data});

  return {
    type: REGISTER_USER,
    payload: request,
  };
}

export function loginUser(dataToSubmit) {
  const request = axios
    .post(`${USER_SERVER}/login`, dataToSubmit)
    .then((response) => response.data);

  return {
    type: LOGIN_USER,
    payload: request,
  };
}

export function auth() {
  const request = axios
    .get(`${USER_SERVER}/auth`)
    .then((response) => response.data);

  return {
    type: AUTH_USER,
    payload: request,
  };
}

export function logoutUser() {
  const request = axios
    .get(`${USER_SERVER}/logout`)
    .then((response) => response.data);

  return {
    type: LOGOUT_USER,
    payload: request,
  };
}
// adds product based on its id to the cart array in User collection
//Flag: Effected by changes in product schema
export function addToCart(params) {
  const { _id, inc, dec, discountedPrice, customizations, customizationId } = params;
  const request = axios
    .post(`${USER_SERVER}/addToCart?productId=${_id}&inc=${inc}&dec=${dec}&decprice=${discountedPrice}&customizationId=${customizationId}`, {customizations})
    .then((response) => {
      return response.data;
    });

  return {
    type: ADD_TO_CART_USER,
    payload: request,
  };
}

// /api/product/products_by_id?id=${productId}&type=single
//Flag: Effected by changes in product schema
export function getCartItems(cartItems, userCart) {
  const request = axios
    .get(`/api/product/products_by_id?id=${cartItems}&type=array`)
    .then((response) => {
      // make cartdetails inside Redux Store
      // we need to add quantity data to product information that come from product colection

      const updatedCartItems = userCart.map((cartItem) => {
        const productDetail = response.data.find((product) => cartItem.id === product._id);
        if (productDetail) {
          return {
            ...cartItem,
            ...productDetail,
            quantity: cartItem.quantity,
            discountedPrice: cartItem.discountedPrice
          };
        }
        return cartItem;
      });

      return updatedCartItems;
    });

  return {
    type: GET_CART_ITEM_USER,
    payload: request,
  };
}

export function removeCartItem(cartItemId) {
  const request = axios
    .get(`/api/users/removeFromCart?cartItemId=${cartItemId}`)
    .then((response) => {
      response.data.cart.forEach((item) => {
        response.data.cartDetail.forEach((k, i) => {
          if (item.id === k._id) {
            response.data.cartDetail[i].quantity = item.quantity;
          }
        });
      });

      return response.data;
    });

  return {
    type: REMOVE_CART_ITEM_USER,
    payload: request,
  };
}

export function onSuccessBuy(data) {
  return {
    type: ON_SUCCESS_BUY_USER,
    payload: data,
  };
}


export function onOrderSucess (data){
  return{
    type: ON_ORDER_SUCCESS,
    payload: data,
  }

}


export function onDeliveryOrderSuccess (data){
  return{
    type: ON_DELIVERY_ORDER_SUCCESS,
    payload: data,
  }
}


export function onAcceptDeliverySuccess (data){
  return{
    type: ON_ACCEPT_DELIVERY_SUCCESS,
    payload: data,
  }
}

export function onDeliveryFeedback(data){
  return{
    type:ON_SEND_DELIVERY_FEEDBACK,
    payload: data,
  }
}


// =========================
// add product id to order
// =========================

export function addToOrder (producerId, productId){
  const request = axios
  .post(`${USER_SERVER}/addToOrder`,{
    params:{
      producerId, productId
    }
  })
  .then((response) => {
   
    return response.data;
  }).catch(err=>{});


return {
  type: ADD_TO_ORDER_USER,
  payload: request,
};
}



// =====================
//    REQUEST DELIVERY
// =====================

export function requestDelivery(data){
   return {
     type: REQUEST_DELIVERY,
     payload: data,
   }

}

//=======================
//    Apply Discount   
//=======================

export function applyDiscount(data){
  const {discountedPrice, productId } = data;
  const request = axios
  .post(`${USER_SERVER}/applyDiscount`, {
    discountedPrice, productId
  });
  return {
    type: APPLY_DISCOUNT,
    payload: data,
  }
}

//============================
// Add producer payment info 
//============================
export function addProducerPaymentInfo(data){
 return {
  type: ADD_PRODUCER_PAYMENT_INFO,
  payload: data,
 }
}


/* export function addProducerPaymentInfo(paymentInfo,userId) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/api/users/user/${userId}/payment-info`,
        paymentInfo
      );
      console.log('before parse', response.data);
      if (response) {
        dispatch({
          type: ADD_PRODUCER_PAYMENT_INFO,
          payload: response.data,
        });
      }

      // Handle success
     // message.success('Payment info submitted successfully.');
      console.log('Payment info submitted successfully:', response.data);
      // Perform any necessary actions after successful submission
    } catch (error) {
      // Handle error
     // message.error('Something went wrong please try again.');
      console.error('Error submitting payment info:', error);
      // Perform any necessary error handling actions
    }
  };
} */
